#header {
    position: fixed;
    width: 5rem;
    z-index: 99999999;
    
  }
  #header .pro-sidebar {
    height: 100vh;
    
  }
  #header .pro-sidebar .styledSidebar {
    background-color: #000;
  }
  #header .pro-sidebar-header {
    border: none;
    margin: 1.5rem 0 0 0; 
  }
  #header .closemenu {
    color: #000;
    position: absolute;

    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 3rem;
   top: 50%;
left: 50%;
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
  }

  #header .openmenu {
    color: #000;
    position: absolute;
    right: 0.5rem;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 3rem;
   top: 50%;
left: 1.5rem;
-webkit-transform:  translateY(-50%);
transform: translateY(-50%);
    cursor: pointer;
  }

  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;
    
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    /* background-color: #000; */
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
    background-color: #FFFFFF;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: 	#A0A0A0;
    color: #000;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-color: 	#E5E5E5;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  #header .logo {
    padding: 20px;
  }
  #header .pro-sidebar-footer{
    border: none;
  }
  .icon:hover{
    color: #000;
    cursor: pointer;
  }
  @media screen {
    
  }

  @media only screen and (max-width: 768px) {
    #header {
      display: none;
    }
  }
  