.video{
    left:50%; top:50%; height: 100%;
    transform: translate(-50%, -50%);  object-fit: cover;
    width: 100%;
    /* z-index: -2;  */
    position: absolute;
  }


  .vertical-line{
    background-color: rgba(128,128,128,0.2);
    width:1px;
    position:absolute;
    left:14.28%;
    top:0;
    bottom:0;
    z-index:-1;
  }
  .vertical-line2{
    background-color:rgba(128,128,128,0.2);
    width:1px;;
    width:1px;
    position:absolute;
    left: 28.57%;
    top:0;
    bottom:0;
    z-index:-1;
  }
  
  .vertical-line3{
    background-color:rgba(128,128,128,0.2);
    width:1px;;
    width:1px;
    position:absolute;
    display: inline-block;
    left: 42.85%;
    top:0;
    bottom:0;
    z-index:-1;
  }
  .vertical-line4{
    background-color:rgba(128,128,128,0.2);
    width:1px;;
    width:1px;
    position:absolute;
    display: inline-block;
    left: 57.13%;
    top:0;
    bottom:0;
    z-index:-1;
  }
  .vertical-line5{
    background-color:rgba(128,128,128,0.2);
    width:1px;;
    width:1px;
    position:absolute;
    display: inline-block;
    left: 71.41%;
    top:0;
    bottom:0;
    z-index:-1;
  }
  .vertical-line6{
    background-color:rgba(128,128,128,0.2);
    width:1px;;
    width:1px;
    position:absolute;
    display: inline-block;
    left: 85.69%;
    top:0;
    bottom:0;
    z-index:-1;
  }

  @import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;1,500&display=swap");

$purple: #8a15ff;
$blue: #3800e7;
$ltblue: #15e0ff;
$magenta: #d000c5;

// This is an example of animations & svgs... I completely understand that the amount of absolute positioning in this file is insane... ;) <3

.all {
  // background: linear-gradient($blue, $purple);
  height: 100vh;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Mono", monospace;
  font-weight: 300;
  overflow: hidden;
  color: white;
  text-align: center;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.2em;
}

h2 {
  font-size: 2em;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -3;
  }
  &:before {
    right: 0;
    bottom: -19;
    height: 30em;
    width: 30em;
    border-radius: 30em;
    background: linear-gradient($blue, $purple);
    align-self: flex-end;
    animation: gradient-fade 8s ease-in-out 3s infinite alternate;
  }
  &:after {
    $circle-unit: 10em;
    top: 0;
    left: 30;
    height: $circle-unit;
    width: $circle-unit;
    border-radius: $circle-unit;
    background: linear-gradient($blue, $purple);
    animation: gradient-fade-alt 6s ease-in-out 3s infinite alternate;
  }

  &__text-wrapper {
    position: relative;
    padding: 2em;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      $circle-unit: 13em;
      z-index: -1;
      top: -3em;
      right: -3em;
      width: $circle-unit;
      height: $circle-unit;
      opacity: 0.7;
      border-radius: $circle-unit;
      background: linear-gradient($ltblue, $purple);
      animation: rotation 7s linear infinite;
    }

    &:after {
      $circle-unit: 20em;
      z-index: -1;
      bottom: -#{$circle-unit};
      width: $circle-unit;
      height: $circle-unit;
      border-radius: $circle-unit;
      background: linear-gradient($magenta, $purple);
      animation: rotation 7s linear infinite;
    }
  }
}

.arrow {
  z-index: 1000;
  opacity: 0.5;
  position: absolute;

  &--top {
    top: 0;
    left: -5em;
  }

  &--bottom {
    bottom: 0;
    right: 3em;
  }
}

.circle {
  transform: translate(50%, -50%) rotate(0deg);
  transform-origin: center;

  &--ltblue {
    $circle-unit: 20em;
    height: $circle-unit;
    width: $circle-unit;
    border-radius: $circle-unit;
    background: linear-gradient($ltblue, $blue);
  }
}

.backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: pink;
}

.dotted-circle {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;
  animation: rotation 38s linear infinite;
}

// animations
.draw-in {
  stroke-dasharray: 1000;
  stroke-dashoffset: 10;
  animation: draw 15s ease-in-out alternate infinite;
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.item-to {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  z-index: -2;
}

.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: ease;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}

.bounce-3 {
  animation-name: bounce-3;
  animation-timing-function: ease;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

// gradient fade

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient-fade {
  from {
    transform: translate(10%, -10%) rotate(0deg);
  }
  to {
    transform: translate(50%, -50%) rotate(360deg);
  }
}

@keyframes gradient-fade-alt {
  from {
    transform: translate(-20%, 20%) rotate(0deg);
  }
  to {
    transform: translate(-60%, 60%) rotate(360deg);
  }
}
