.cart{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 5rem 0;
}
    @media only screen and(max-width: 1400px) {
        .cart{
            display: flex !important;
       flex-direction: column !important;
        align-items: center !important;
        width: 880px !important;
        background:red !important;
        }
    }
  
   
