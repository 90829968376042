*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  
}
.vertical-line{
  background-color: rgba(128,128,128,0.2);
  width:1px;
  position:absolute;
  left:14.28%;
  top:0;
  bottom:0;
  z-index:-1;
}
.vertical-line2{
  background-color:rgba(128,128,128,0.2);
  width:1px;;
  width:1px;
  position:absolute;
  left: 28.57%;
  top:0;
  bottom:0;
  z-index:-1;
}

.vertical-line3{
  background-color:rgba(128,128,128,0.2);
  width:1px;;
  width:1px;
  position:absolute;
  display: inline-block;
  left: 42.85%;
  top:0;
  bottom:0;
  z-index:-1;
}
.vertical-line4{
  background-color:rgba(128,128,128,0.2);
  width:1px;;
  width:1px;
  position:absolute;
  display: inline-block;
  left: 57.13%;
  top:0;
  bottom:0;
  z-index:-1;
}
.vertical-line5{
  background-color:rgba(128,128,128,0.2);
  width:1px;;
  width:1px;
  position:absolute;
  display: inline-block;
  left: 71.41%;
  top:0;
  bottom:0;
  z-index:-1;
}
.vertical-line6{
  background-color:rgba(128,128,128,0.2);
  width:1px;;
  width:1px;
  position:absolute;
  display: inline-block;
  left: 85.69%;
  top:0;
  bottom:0;
  z-index:-1;
}